import React, { useContext } from 'react';
import {
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Box,
  Stack,
  Button,
  Skeleton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useProfile } from 'api/profile';
import { isSiteAccess, useGetToken, useLogout } from 'utils/token';
import { linkHelper } from 'linkHelper';
import { useBillingSso } from 'api/auth';
import { useNewDesignSwitch } from 'component/hooks/useNewDesignSwitch';
import IconMenuItem from './IconMenuItem';

// icons
import BurgerMenuThree from '../icons/BurgerMenuThree.svg?react';
import Monitor from '../icons/Monitor.svg?react';
import User from '../icons/User.svg?react';
import CreditCard from '../icons/CreditCard.svg?react';
import UserThree from '../icons/UserThree.svg?react';
import Sun from '../icons/Sun.svg?react';
import Moon from '../icons/Moon.svg?react';
import LogOutRight from '../icons/LogOutRight.svg?react';
import Refresh from '../icons/Refresh.svg?react';
import { Gravatar } from '../base/Gravatar';
import { ColorModeContext } from '../root/AutoThemeProvider';

interface Props {
  readonly hasData?: boolean;
}

export function MenuButton({
  selected,
  icon,
  text,
  onClick,
}: {
  readonly selected?: boolean;
  readonly icon: React.ReactNode;
  readonly text: string;
  readonly onClick: () => void;
}) {
  return (
    <Button
      fullWidth
      size="small"
      variant="contained"
      color="tertiary"
      startIcon={icon}
      sx={{
        color: selected ? 'greys.900' : 'greys.400',
        backgroundColor: selected ? 'common.white' : 'transparent',
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
}

export default function UserMenu({ hasData }: Props) {
  const logout = useLogout();
  const token = useGetToken();
  const { mutateAsync: getBillinglink } = useBillingSso();
  const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const userMenuOpen = Boolean(userMenuAnchorEl);
  const { t } = useTranslation();
  const { isLoading, data } = useProfile();
  const navigate = useNavigate();
  const [colorMode, setColorMode] = useContext(ColorModeContext);

  const handleUserMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  const shouldShowMenu = hasData && token && !isSiteAccess(token);
  const { toggleNewDesign } = useNewDesignSwitch(true);

  const name = `${data?.data.result?.client?.firstname} ${data?.data.result?.client?.lastname}`;
  const email = data?.data.result?.client?.email ?? '';

  return (
    <>
      {shouldShowMenu ? (
        <IconButton
          color="header"
          id="user-menu-button"
          aria-controls={userMenuOpen ? 'user-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={userMenuOpen ? 'true' : undefined}
          onClick={handleUserMenuClick}
        >
          <Stack spacing={1} direction="row" alignItems="center">
            {isLoading ? (
              <Skeleton variant="circular" width="28px" height="28px" />
            ) : (
              <Gravatar size="m" name={name} email={email} />
            )}
            <BurgerMenuThree />
          </Stack>
        </IconButton>
      ) : null}
      <Menu
        id="user-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={userMenuAnchorEl}
        open={userMenuOpen}
        onClose={handleUserMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          marginTop: '50px',
        }}
      >
        <MenuItem
          sx={theme => ({
            pointerEvents: 'none',
            borderBottom: `1px solid ${theme.palette.divider}`,
            marginLeft: -1,
            marginRight: -1,
            marginBottom: 1,
            px: 3,
            borderRadius: 0,
            '&:hover': {
              backgroundColor: 'transparent',
            },
          })}
        >
          <Stack direction="row" width="100%" gap={2}>
            <Gravatar size="l" name={name} email={email} />
            <Stack gap={0.5}>
              <Typography variant="body2" color="greys.900" fontWeight="600">
                {name}
              </Typography>
              <Typography variant="caption" color="greys.500">
                {email}
              </Typography>
            </Stack>
          </Stack>
        </MenuItem>
        <IconMenuItem
          title={t('account_and_security')}
          icon={<User />}
          onClick={() => {
            navigate(linkHelper.newDesign.settings.accountAndSecurity.getLink());
            handleUserMenuClose();
          }}
        />
        <IconMenuItem
          title={t('billing')}
          icon={<CreditCard />}
          onClick={async () => {
            navigate(linkHelper.newDesign.settings.billing.getLink());
            handleUserMenuClose();
          }}
        />
        <IconMenuItem
          title={t('users')}
          icon={<UserThree />}
          onClick={() => {
            navigate(linkHelper.newDesign.settings.users.getLink());
            handleUserMenuClose();
          }}
        />
        <IconMenuItem
          title={t('site_templates')}
          icon={<Monitor />}
          onClick={() => {
            navigate(linkHelper.newDesign.settings.siteTemplates.getLink());
            handleUserMenuClose();
          }}
        />
        <MenuItem
          sx={theme => ({
            pointerEvents: 'none',
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            marginLeft: -1,
            marginRight: -1,
            marginTop: 1,
            marginBottom: 1,
            p: 3,
            borderRadius: 0,
            '&:hover': {
              backgroundColor: 'transparent',
            },
          })}
        >
          <Stack width="100%" gap={1}>
            <Typography variant="body2" color="greys.500" fontWeight="400">
              {t('theme')}
            </Typography>
            <Box
              sx={{
                pointerEvents: 'auto',
                borderRadius: 'var(--border-radius-l)',
                backgroundColor: 'greys.50',
                padding: 1,
              }}
            >
              <Stack
                direction="row"
                sx={{
                  width: '100%',
                }}
                gap={1}
              >
                <MenuButton
                  onClick={() => setColorMode('light')}
                  selected={colorMode === 'light'}
                  icon={<Sun />}
                  text={t('light')}
                />
                <MenuButton
                  onClick={() => setColorMode('dark')}
                  selected={colorMode === 'dark'}
                  icon={<Moon />}
                  text={t('dark')}
                />
                <MenuButton
                  onClick={() => setColorMode('auto')}
                  selected={colorMode === 'auto'}
                  icon={<Monitor />}
                  text={t('auto')}
                />
              </Stack>
            </Box>
          </Stack>
        </MenuItem>
        <IconMenuItem
          onClick={toggleNewDesign}
          title={t('switch_ui_to_classic_mode')}
          icon={<Refresh />}
        />
        <IconMenuItem
          color="reds.500"
          title={t('logout')}
          icon={<LogOutRight />}
          onClick={logout}
        />
      </Menu>
    </>
  );
}
