import { useTranslation } from 'react-i18next';
import { TableColumnType, TableRowActionType } from 'component/new_design/base/Table/types';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { Table } from 'component/new_design/base/Table';
import { formatLocaleDate } from 'utils/dateFormat';
import { AutomatedBackup, useGetAutomatedBackups } from 'api/backups';
import { useState } from 'react';
import { AutomatedBackupRestoreDialog } from '../../SiteDetails/Backups/AutomatedBackupRestoreDialog';
import { BackupStatusCell } from './BackupStatusCell';
import Server from 'component/new_design/icons/Server.svg?react';
import { EmptyState } from 'component/new_design/base/EmptyState';
import { useCheckUserRole } from 'component/hooks/useCheckUserRole';

interface AutomatedBackupsTableProps {
  readonly siteId: string;
}

export function AutomatedBackupsTable({ siteId }: AutomatedBackupsTableProps) {
  const { t, i18n } = useTranslation();

  const [recoveryPointToRestore, setRecoveryPointToRestore] = useState<string | null>();

  const tableState = usePaginatedListState({ urlStatePrefix: 'automated' });

  const { data, isLoading } = useGetAutomatedBackups(siteId, tableState);

  const showActions = !useCheckUserRole('billing_admin');

  const columns: TableColumnType<AutomatedBackup>[] = [
    {
      label: t('label'),
      renderValue: () => t('platform_backup'),
    },
    {
      label: t('status'),
      key: 'status',
      renderValue: backup => <BackupStatusCell backup={backup} />,
    },
    {
      label: t('created'),
      key: 'created_at',
      renderValue: backup => formatLocaleDate({ locale: i18n.language, value: backup.created_at }),
    },
  ];

  const rowActions: TableRowActionType<AutomatedBackup>[] = showActions
    ? [
        {
          label: t('restore'),
          inline: true,
          onClick: backup => setRecoveryPointToRestore(backup.recoveryPoint!),
        },
      ]
    : [];

  return (
    <>
      <Table
        actionColumnOverrides={{
          minWidth: '80px',
        }}
        title={t('automated_backups')}
        label={t('automated_backups')}
        columns={columns}
        data={data?.data.result || []}
        totalRowCount={data?.data.metadata?.total || 0}
        isLoading={isLoading}
        state={tableState}
        rowActions={rowActions}
        emptyState={
          <EmptyState
            icon={<Server />}
            title={t('no_data_yet')}
            description={t('here_will_be_list_of_automated_platform_backups')}
          />
        }
      />
      {recoveryPointToRestore ? (
        <AutomatedBackupRestoreDialog
          siteId={siteId}
          backupId={recoveryPointToRestore}
          onClose={() => setRecoveryPointToRestore(null)}
        />
      ) : null}
    </>
  );
}
